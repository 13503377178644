<template>
  <v-card
    class="min-h-full mx-auto mt-8 common_background"
    outlined
    loader-height="2"
    :loading="loading"
    max-width="450"
  >
    <v-form ref="frmLogin" class="d-flex flex-column align-start pa-4" @submit.prevent="onLogin">
      <v-card-title>
        <h2>{{ $t("Login") }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              :label="$t('Email')"
              :rules="rules"
              hide-details="auto"
              @keyup="resetNotification()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              :label="$t('Password')"
              :rules="rules"
              hide-details="auto"
              type="password"
              @keyup="resetNotification()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              type="submit"
              class="mb-3 text-capitalize"
              block
              color="green white--text"
            >
              {{ $t('Login') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense class="mb-3">
          <v-col>
            <v-btn class="white--text text-capitalize" block color="#4285f4" @click="signinwithGoogle">
              <v-icon left> mdi-google </v-icon> {{ $t('SignInGoogle') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-btn class="mb-2 white--text text-capitalize" block color="#4285f4" :to="{ name: 'Register' }">
              {{ $t('SignUp') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <p class="text-right">
              <router-link class="no-underline" :to="{ name: 'ForgotPassword' }">Forgot password?</router-link>
            </p>
          </v-col>
        </v-row>
        <v-row dense class="text-center">
          <v-col>
            <span
              v-if="!!notificationDetail && notificationDetail.isNotification"
              :class="notificationDetail.notificationType==='error'? 'red--text':'green--text'"
            >
              {{ notificationDetail.message }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {
  FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD,
  FIREBASE_SIGNIN_WITH_GOOGLE,
  FIREBASE_SIGNIN_WITH_TWITTER,
  FIREBASE_SEND_VERIFICATION_EMAIL,
} from '@/store/account/action'
import { get, dispatch, call } from 'vuex-pathify'
import firebase from 'firebase'

export default {
  name: 'Login',
  data: () => ({
    loading: false,
    selection: 1,
    rules: [
      (value) => !!value || 'Required.',
      (value) => (value && value.length >= 3) || 'Minimum 3 characters',
    ],
    email: '',
    password: '',
  }),
  computed:{
    ...get('account', ['notificationDetail', 'isVerifiedEmailUser']),
  },
  methods: {
    ...call('account', [
      'SET_MESSAGE',
      'FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD',
      'FIREBASE_SEND_VERIFICATION_EMAIL',
      'FIREBASE_SIGNIN_WITH_GOOGLE',
    ]),
    resetNotification() {
      const notificationDetail = {
        message: '',
        pageDetails: '',
        apidetails: '',
        notificationType: '',
        isNotification: false,
      }

      this.SET_MESSAGE(notificationDetail)
    },
    async onLogin() {
      this.loading = true
      if (this.$refs.frmLogin.validate()) {
        const payload = {
          email:this.email,
          password:this.password,
        }

        await this.FIREBASE_SIGNIN_WITH_EMAIL_PASSWORD(payload)
        const user = firebase.auth().currentUser

        this.loading = false
        if (user && user.emailVerified) {
          this.$router.replace({ name: 'Home' })
        } else if (user !== null && !user.emailVerified) {
          this.SendVerification()
        } else {
          const notificationDetail = {
            message: 'Please check your username and password, If you still can\'t log in, contact your BlockChainMetrics administrator',
            pageDetails: 'Login',
            apidetails: 'Login Page',
            notificationType: 'error',
            isNotification: true,
          }

          this.SET_MESSAGE(notificationDetail)
        }
      }
    },
    async SendVerification() {
      await this.FIREBASE_SEND_VERIFICATION_EMAIL()
    },
    async signinwithGoogle() {
      this.loading = true
      const provider = new firebase.auth.GoogleAuthProvider()

      await this.FIREBASE_SIGNIN_WITH_GOOGLE(provider)
      this.loading = false

      this.$router.replace({ name: 'Home' })
    },
  },
}
</script>
